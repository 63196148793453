@import "https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:400,600&display=swap";
@import "https://unpkg.com/carbon-components/css/carbon-components.min.css";


.fields-statistic {
  padding: 48px 48px 0 48px;
}

.reports {
  overflow: hidden;
}
.panel {
  z-index: 1;
  position: absolute;
  float: right;
  right: 0;
  height: calc(99vh - 3rem - 80px);
}

.panel>div {
  margin: 8px;
  padding: 24px;
  background: #FFF;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
}

.panel {
  overflow-y: auto;
}

.toolbar {
  visibility: hidden;
  display: none;
}

.panel .reports {
  padding: 0;
}

.header-div {
  display: flex;
  justify-content: space-between;
}

.header-div>svg {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.panel .chart {
  display: none;
}

.title {
  font-size: 150%;
}