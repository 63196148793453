.search, .filters {
  display: flex;
}

.search {
  width: 100%;
  height: 80px;
  padding: 0 10px 0 20px;
  justify-content: space-between;
  align-items: center;
}

.filters {
  gap: 10px;
  padding: 10px 0;
}

.title {
  font-size:2em;
}

.actions {
  align-self: flex-end;
}