.header {
  height: 3rem;
  margin-bottom: 48;
}

.logo-div {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  gap: 20px;
}

.logo, .client-logo {
  height: 40px;
  margin: auto;
}

.client-logo {
  margin: auto;
  
}

.tag {
  height: 10px;
  margin: auto;

  display: none;
}

.underscored>a>span {
  padding: 2px;
  border-bottom: 1px solid hsla(0, 0%, 9%, 0.515);
}

.role-div {
  height: 60%;
  margin-left: 10px;
  border-left: 1px solid rgb(233, 233, 233);
  display: flex;
  align-items: center;
  padding: 0 10px;
}