@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;700&display=swap");

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dashboard {
  width: 450px;
  height: calc(99vh - 3rem - 90px);
  position: absolute;
  z-index: 10;
  top: 86px;
  left: 6px;
  background-color: white;
}

.breadcrumb {
  margin: 5px 0 0 5px;
  padding: 20px;
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumb a {
  font-size: 20px;
  color: rgb(46, 46, 46) !important;
}

.breadcrumb a:hover {
  color: rgb(46, 46, 46);
}

.breadcrumb .a-current a {
  color: rgb(99, 99, 99) !important;
}

.dashboard-items-div {
  margin: 5px 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: overlay;
  height: calc(99vh - 10rem - 90px);
}

.list-item-name {
  margin-right: 20px;
  cursor: pointer;
  color: #0f62fe;
  font-feature-settings: "clig" off, "liga" off;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
}

.list-item-name, .list-item-name-span {
  flex-basis: 45%;
}

.list-item {
  border: 1px solid rgb(233, 233, 233);;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.list-header-item {
  border: 1px solid rgb(233, 233, 233);;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--cds-layer-accent);
}

 .list-header-name-span {
  flex-basis: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: bold;
}

.list-header-name-span-last {
  flex-basis: 48%;
  text-align: end;
  font-weight: bold;
}
