.indicator {
  height: 10px;
  width: 100px;
  display: flex;
}

.green {
  background-color: #29A863;
}

.red {
  background-color: #E21B00;
}

.red, .green {
  height: 100%;
}
