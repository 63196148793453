.login-form {
  background-color: rgba(255, 255, 255, 0.96);
  height: 517px;
  width: 76vw;
  display: flex;
  align-items: center;

}

.login-form button {
  float: inline-end;
  justify-self: end;
}

.login-form>div {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  border-right: 1px solid #E0E0E0;
}

.login-form>form {
  flex-basis: 50%;
  padding: 0 8vw;
}

.login-form>form section {
  display: flex;
  width: 100%;
}