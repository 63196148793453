.map-container, .map {
  width: 100vw;
  height: calc(100Vh - 3rem - 80px);
}

.map {
  position: absolute;
  z-index: -1;
}

.noc {
  width: 130px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 50px;
}