.report-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
}

.report-form>section {
  width: 30vw;
  margin-bottom: 20px;
}