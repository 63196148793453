.label {
  color: #161616;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.uploader-container {
  margin-bottom: 10px;
}

.uploader-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.uploader-item>svg {
  cursor: pointer;
  margin-bottom: 10px;
}