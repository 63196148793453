.stepper-content {
}

.indicator, .stepper-content {
  width: 100%;
}

.stepper-content>div, .stepper-actions {
  margin-top: 24px;
}

.stepper-actions {
  display: flex;
  justify-content: flex-end;
}