.label {
  color: #161616;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin-bottom: 8px;
}

.text {
  color: var(--text-text-01, #161616);
  font-feature-settings: "clig" off, "liga" off;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.16px;
  margin-bottom: 40px;
}
