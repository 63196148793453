.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
}
#map {
  width: 100vw;
  height: 100vh;
}
.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}

.pin {
  height: 50vh;
  width: 100vw;
}

.mapboxgl-control-container, .pigeon-attribution, .leaflet-bottom, .leaflet-right {
  display: none;
}
.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-marker-icon {
  transition: 0.2s;
}

.report-section, .login-section {
  display: flex; 
  flex-wrap: wrap;
  height: 100%;
}

.report-section {
  flex-direction: column;
}

.map-section {
  display: flex; ;
  flex-wrap: wrap;
}

.login-section {
  justify-content: flex-end;
  align-items: center;
  padding-right: 10vw;
}

.map-section>header {
  height: 3rem;
  width: 100%;
  flex-basis: 100%;
}

.map-content {
  width: 100%;
  display: flex;
  height: 100%;
  position: relative;
}

.map-content>section {
  flex-basis: 25%;
}

.busy {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo {}