@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;700&display=swap');

.heading-div {
  padding-left: 16px;
}

.heading-div>h2 {
  font-size: 24px;
  margin-bottom: 2px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
}

.heading-div>span {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
}