.flex-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 0;
}

.flex-content > div {
  width: 100%;
}

.flex-content label {
  font-size: 14px;
}

.underscored {
  border-bottom: 1px solid #CACACA!important;
  padding-bottom: 20px;
}

.form-value {
  color: #161616;
  text-align: right;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.underscored + div {
  padding-top: 20px;
}

.approved, .submit, .reject, .draft {
  text-transform: capitalize;
}

.approved {
  color: green;
}

.submit {
  color: #FFA800;
}

.reject {
  color: #E21B00;
}

.overdue {
  color: black
}

.switcher {
  width: 252px;
}

.value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}