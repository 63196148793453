.svg-icon {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

