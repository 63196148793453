.uploader-div, .uploader-container {
  width: 1000px!important;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  gap: 20px;
}


.date-picker div, .uploader-item {
  width: 100%;
}

.radio-div, .flex-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  
}

.switcher {
  width: 252px;
}


.flex-content {
  gap: 20px;
}

.flex-content>div {
  width: 100%;
}
