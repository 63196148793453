.date-picker {
  width: 100%;
}

.date-picker div {
  width: 100%;
}

.radio-div, .flex-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  
}

.switcher {
  width: 252px;
}


.flex-content {
  gap: 20px;
}

.flex-content>div {
  width: 100%;
}
