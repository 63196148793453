.reports {
  padding: 48px;
}

.add {
  width: 200px;
}

.toolbar-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.approved, .submit, .reject, .draft {
  text-transform: capitalize;
}

.approved {
  color: green;
}

.submit {
  color: #FFA800;
}

.reject, .tdanger {
  color: #E21B00;
}

